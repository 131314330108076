import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { svgModel } from './svg-model';

@Directive({
  selector: '[svgIcon]',
  standalone: true,
})
export class SvgInlineDirective implements OnInit {
  @Input() svgIcon: string | null = null; // Input to accept icon key or raw SVG
  svgModelEnum = svgModel; // Reference to svgModel

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.setSVGContent();
  }

  setSVGContent(): void {
    if (this.svgIcon) {
      // Check if the input is a key in the svgModel
      const svgContent = this.svgModelEnum[this.svgIcon as keyof typeof svgModel];

      // If the svgIcon exists in svgModel, use it. Otherwise, assume it's raw SVG.
      this.el.nativeElement.innerHTML = svgContent ? svgContent : this.svgIcon;
    }
  }
}
